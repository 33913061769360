<template>
  <div
    :class="{ 'shrinkreport-content': drawer && !isMobile }"
    style="margin-top: 65px; padding: 5px"
  >
    <v-container fluid style="position:relative;">

      <v-chip class="selected" v-if="selectedCategory" :class="{ 'mobile' : isMobile }">{{ selectedCategory }}</v-chip>

      <v-row v-if="loadingVideos" class="videos-container">
        <v-col offset-xl="3" xl="6" offset-lg="2" lg="8" offset-sm="2" sm="8" cols="12">
          <v-skeleton-loader 
            class="mx-auto"
            type="card"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else-if="embeddedVideo">
        <v-col offset-xl="3" xl="6" offset-lg="2" lg="8" offset-sm="2" sm="8" cols="12">
          <div class="video_wrapper" v-html="embeddedVideo.embeddedCode">
          </div>
        </v-col>
      </v-row>

      <div v-if="videos && videos.length > 0">
        <v-row>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row style="overflow-x: auto;flex-wrap: nowrap;">
          <v-col lg="2" md="3" sm="4" cols="6" v-for="video of videos" v-bind:key="video.id" style="text-align:center">
            <router-link class="video-container" :to="'/videos/' + video.id + (selectedCategoryId ? '?categoryId=' + selectedCategoryId : '' )">
              <v-hover v-slot="{ hover }" v-if="video.thumbnailUrl">
                <div class="video_wrapper">
                  <img :src="video.thumbnailUrl" class="video-thumbnail" :class="{ 'hover': hover }" />
                  <v-chip>
                    {{ video.duration }}
                  </v-chip>
                </div>
              </v-hover>           
              <div v-else class="not-available">
                <div>
                  <v-icon style="font-size:50px;">mdi-alert-circle-outline</v-icon>
                  <div class="not-available-text">{{ $t('video.video_not_available') }}</div>
                </div>
              </div>
            </router-link>
            <div class="video-title">{{ video.title }}</div>
            <div class="video-subtitle">{{ video.creator }}</div>
            <div class="video-subtitle">{{ video.company }}</div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import authHeader from "@/services/auth-header";
import constants from "@/constants";
import { parseError } from "@/utils/utils"
import { VIDEO_CATEGORY } from "@/enums";
import { getThumb } from '@/utils/video-thumbnail';

const axios = require('axios');
var API_URL = constants.API_URL + "video/";

export default {
  name: "Videos",
  components: {
  },
  watch: {
    $route() {
      this.selectedCategoryId = this.$route.query.categoryId;
      this.reloadVideos();
    }
  },
  data: () => {
    return {
      embeddedVideo: null,
      videos: null,
      selectedCategory: null,
      selectedCategoryId: null,
      loadingVideos: true,
      VIDEO_CATEGORY: VIDEO_CATEGORY
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
    })
  },
  methods: {
    ...mapMutations([
      'showErrorMessage'
    ]),
    ...mapActions({
    }),
    async reloadVideos() {
      try {
        this.loadingVideos = true;

        let videoId = this.$route.params.id;

        let url = API_URL + videoId + "?language=" + this.$i18n.locale;
        if (this.selectedCategoryId) {
          url += '&categoryId=' + this.selectedCategoryId;
        }

        let res = await axios.get(url, { headers: authHeader() });
        console.log('getVideos', res)
        
        if (res && res.data) {
          this.embeddedVideo = res.data.embeddedVideo;
          this.videos = res.data.videos;
          this.selectedCategory = res.data.category;

          for (let video of this.videos) {
            video.thumbnailUrl = await getThumb(video.url);
          }
        }
      }
      catch (err) {
        this.showErrorMessage(parseError(err));
        this.$router.push('/videos');
      }
      finally {
        this.loadingVideos = false;
      }
    }
  },
  async mounted() {
    this.selectedCategoryId = this.$route.query.categoryId;
    this.reloadVideos();
  },
};
</script>

<style scoped>
.v-chip {
  cursor: pointer;
}
.v-chip.selected {
  background: #009f4d;
  color: white;
}
  .v-chip + .v-chip {
    margin-left: 10px;
  }

.video-container {
  display: block;
  position: relative;
  cursor: pointer;
  text-decoration: none;
}
.video-container:hover {
  opacity: 0.8;
}

.video-container img {
  max-width: 100%;
}

.videos-container {
  max-height: unset;
  overflow: auto;
}

.not-available {
  text-align: center;
  background-color: #8080805e;
  width: calc(100% - 10px);
  margin-left: 5px;
  aspect-ratio: 4 / 3;
}
.not-available > div {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}
.not-available-text {
  color: #5C5C5C;
  font-weight: bold;
  font-size: 1em;
  margin-top: 15px;
}

.video-title,
.video-subtitle {
  text-align: left; 
  margin-left: 5px;
  word-break: break-all;
}

.video-title {
  font-weight: bold;
}


.video-container .v-chip {
  position: absolute;
  right: 3px;
  bottom: 8px;
}

@media (min-width: 1000px) {
  .videos-container {
    max-height: calc(100vh - 200px);
  }
}

img.hover {
  box-shadow: 0 3px 5px -1px rgba(0,0,0,.3),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.2)!important;
}

.video_wrapper {
  position: relative;
  padding-bottom: 75%;
}

.video-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

::v-deep iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.v-chip.mobile {
  margin-bottom: 15px;
}

</style> 